<template>
  <v-container fluid>
    <v-form ref="form" lazy-validation v-if="isform">
      <v-dialog
        v-model="showform"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
        max-width="1000"
      >
        <v-card>
          <v-card-title>
            <span> {{ title }} </span>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <listitemspages
              :selitem="selitem"
              :qSelect="Qselect"
              :qSelectall="Qselectall"
              :title="title"
              :PageResponse="'docs'"
              :AllResponse="'alldocs'"
              :headers="doc_type == -1 ? docs_headers : ged_headers"
              :field="'CREATED_AT'"
              :filename="'Documents'"
              :sheetname="'Documents'"
              :Add="editer"
              :del="editer"
              :del_disable="document.nbrevisions > 0"
              :showedit="false"
              @rowselect="FileSelect"
              @open="OpenFileForm"
              @hrf="PreviewFile"
              @col_btn1_click="UpdateDocument"
              @col_btn2_click="downloadItem"
              @delete="delete_file"
              :WhereExt="WhereExt"
              :key="klist"
              :exp1_label="'Actif'"
              :exp2_label="'Supprimé'"
              :list_options="$store.state.isadmin"
              @contextmenu="contextmenu"
            >
            </listitemspages>
          </v-card-text>
          <v-card-actions>
            <v-spacer> </v-spacer>

            <v-btn color="blue darken-1" text @click.stop="close">
              Fermer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <v-row v-else>
      <v-col cols="12" sm="12" md="12" v-if="doc_download">
        <v-progress-linear
          :value="progress_value"
          :color="'light-green darken-2'"
          height="25"
          rounded
          :key="pl"
        >
          <template v-slot:default>
            {{ progress_value + "% " }}
          </template>
        </v-progress-linear>
      </v-col>

      <v-col cols="12" sm="12" md="12">
        <listitemspages
          :selitem="selitem"
          :qSelect="Qselect"
          :qSelectall="Qselectall"
          :title="title"
          :PageResponse="'docs'"
          :AllResponse="'alldocs'"
          :headers="doc_type == -1 ? docs_headers : ged_headers"
          :field="'CREATED_AT'"
          :filename="'Documents'"
          :sheetname="'Documents'"
          :Add="editer"
          :del="editer"
          :del_disable="document.nbrevisions > 0"
          :showedit="false"
          @rowselect="FileSelect"
          @open="OpenFileForm"
          @hrf="PreviewFile"
          @col_btn1_click="UpdateDocument"
          @col_btn2_click="downloadItem"
          @delete="delete_file"
          :WhereExt="WhereExt"
          :CatScope="CatScope"
          :key="klist"
          :exp1_label="'Actif'"
          :exp2_label="'Supprimé'"
          :list_options="$store.state.isadmin"
          @contextmenu="contextmenu"
        >
        </listitemspages>
      </v-col>
    </v-row>

    <fileform
      :item="document"
      :showForm="!isDocClose"
      :extensions="ends"
      :isRevision="isRevision"
      :key="ff"
      :doc_type="doc_type"
      :categories="categories"
      @close="isDocClose = true"
      @added="file_added"
    >
    </fileform>
    <fileproperties
      :document="document"
      :showform="!isPropClose"
      :key="fpp"
      :ends="ends"
      :groups="groups"
      :users="users"
      @close="isPropClose = true"
      @changed="klist++"
      @data_change="klist++"
    >
    </fileproperties>
    <confirmdialog ref="confirm" />
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-list>
        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item v-if="document.preview" @click="PreviewFile(document)">
            <v-list-item-title>Aperçu</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>Copier</v-list-item-title>
          </v-list-item>
          <v-list-item @click="show_properties">
            <v-list-item-title>Propriétés</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </v-container>
</template>
<script>
function list_to_tree(list) {
  var map = {},
    node,
    roots = [],
    i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i; // initialize the map
    list[i].children = []; // initialize the children
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    node.index = i;
    if (node.fk_parent !== "0") {
      // if you have dangling branches check that map[node.parentId] exists
      if (list[map[node.fk_parent]])
        list[map[node.fk_parent]].children.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
}
let ends = [];
import UPDATE_DOC from "../graphql/File/UPDATE_DOC.gql";
import DELETE_DOC from "../graphql/File/DELETE_DOC.gql";
import DELETE_DOC_PERMISSION from "../graphql/File/DELETE_DOC_PERMISSION.gql";
import DOCS from "@/graphql/DOCS.gql";
import DOCS_PAGES from "@/graphql/DOCS_PAGES.gql";
import EXTENSION from "../graphql/File/EXTENSION.gql";
import DATA from "../graphql/File/DATA.gql";
import { api } from "print/data.js";

export default {
  components: {
    listitemspages: () => import("./ListItemsPages.vue"),
    fileproperties: () => import("../components/FileProperties.vue"),
    fileform: () => import("./FileForm.vue"),
    confirmdialog: () => import("./ConfirmDialog.vue"),
  },
  props: {
    showform: Boolean,
    editer: Boolean,
    doc_type: Number,
    item: Object,
    isform: { type: Boolean, default: true },
    auth: String,
    title: { type: String, default: "Documents Attachés" },
    categories: Array,
  },
  data() {
    return {
      mini: true,
      drawer: false,
      tab1: "1",
      selitem: {},
      document: {},
      document_rev: {},
      permission: {},
      isDocClose: true,
      isPermClose: true,
      isPropClose: true,
      isRevision: false,
      showMenu: false,
      selectedItem: 0,
      x: 0,
      y: 0,
      ff: 900,
      fp: 1000,
      fpp: 1100,
      klist: 0,
      kpermis: 100,
      krev: 200,
      kt: 300,
      return_list: [],
      CatScope: [],
      WhereExt: [],
      snackbar: false,
      snackbar_timeout: 2000,
      snackbar_text: "",
      snackbar_color: "primary",
      ends: [],
      users: [],
      groups: [],
      active: [],
      items: [],
      pl: 1000,
      docs_headers: [
        {
          text: "No",
          value: "no",
          selected: true,
          sortable: false,
        },
        {
          text: "Reference",
          value: "ref",
          enum: "REF",
          selected: true,
        },
        {
          text: "Titre",
          value: "title",
          enum: "TITLE",
          width: "120px",
          selected: true,
        },
        {
          text: "Description",
          value: "description",
          enum: "DESCRIPTION",
          selected: false,
        },
        {
          text: "Fichier/Url",
          value: "filename",
          enum: "FILENAME",
          slot: "preview",
          width: "120px",
          preview: "preview",
          selected: true,
        },
        {
          text: "Document",
          value: "doc_type_name",
          enum: "DOC_TYPE_NAME",
          list: [
            "User",
            "Client/Fournisseur",
            "Mouvement",
            "Paiement/Encaissement",
          ],
          selected: true,
        },
        {
          text: "Type",
          value: "file_icon",
          slot: "icon",
          enum: "FILE_TYPE",
          list: [],
          tooltip: "file_type",
          selected: true,
        },
        {
          text: "Taille (ko)",
          value: "size",
          enum: "SIZE",
          align: "end",
          slot: "cur33",
          selected: false,
        },
        {
          text: "Date",
          value: "doc_date",
          enum: "DOC_DATE",
          slot: "date",
          selected: true,
        },
        {
          text: "Version",
          value: "version",
          enum: "VERSION",
          selected: true,
        },
        {
          text: "Url?",
          value: "isurl",
          slot: "checkbox",
          enum: "ISURL",
          selected: false,
        },
        {
          text: "Actif?",
          value: "isActive",
          slot: "checkbox",
          enum: "ACTIVE",
          selected: false,
          hiden: true,
        },
        {
          text: "Propriétaire",
          value: "owner",
          enum: "OWNER",
          selected: true,
        },
        {
          text: "Ref. Document",
          value: "nodoc",
          enum: "NODOC",
          selected: false,
        },

        {
          text: "Partagé ?",
          value: "partage_icon",
          slot: "icon",
          search: "yes/no",
          enum: "ISPUBLIC",
          selected: true,
        },
        {
          text: "Revisions",
          value: "nbrevisions",
          enum: "NBREVISIONS",
          selected: false,
        },
        {
          text: "Tier",
          value: "tier_name",
          enum: "TIER_NAME",
          selected: false,
        },
        {
          text: "Localisation",
          value: "position",
          enum: "POSITION",
          selected: false,
        },
        {
          text: "",
          slot: "col_btn1",
          icon: "mdi-update",
          selected: true,
          width: "10",
          column: "canWrite",
          valeur: true,
          tooltip: "Modifier document",
          sortable: false,
        },
        {
          text: "",
          slot: "col_btn2",
          icon: "mdi-download",
          selected: true,
          width: "10",
          tooltip: "Telecharger document",
          column1: "isurl",
          valeur1: 0,
          sortable: false,
        },
      ],
      ged_headers: [
        {
          text: "No",
          value: "no",
          selected: true,
          sortable: false,
        },
        {
          text: "Reference",
          value: "ref",
          enum: "REF",
          selected: true,
        },
        {
          text: "Titre",
          value: "title",
          enum: "TITLE",
          selected: true,
        },
        {
          text: "Description",
          value: "description",
          enum: "DESCRIPTION",
          selected: false,
        },
        {
          text: "Fichier/Url",
          value: "filename",
          enum: "FILENAME",
          slot: "preview",
          preview: "preview",
          selected: true,
        },

        {
          text: "Type",
          value: "file_icon",
          slot: "icon",
          enum: "FILE_TYPE",
          list: [],
          tooltip: "file_type",
          selected: true,
        },
        {
          text: "Taille (ko)",
          value: "size",
          enum: "SIZE",
          align: "end",
          slot: "cur33",
          selected: false,
        },
        {
          text: "Date",
          value: "doc_date",
          enum: "DOC_DATE",
          slot: "date",
          selected: true,
        },

        {
          text: "Url?",
          value: "isurl",
          slot: "checkbox",
          enum: "ISURL",
          selected: false,
        },
        {
          text: "Propriétaire",
          value: "owner",
          enum: "OWNER",
          selected: true,
        },
        {
          text: "",
          slot: "col_btn2",
          icon: "mdi-download",
          selected: true,
          width: "10",
          tooltip: "Telecharger document",
          column1: "isurl",
          valeur1: 0,
          sortable: false,
        },
      ],
      retablir: false,
      doc_download: false,
      progress_value: 0,
    };
  },
  watch: {},
  async created() {
    this.docs_headers[11].hiden = !this.$store.state.isadmin;
    if (this.doc_type >= 0) {
      this.WhereExt = [
        { column: "DOC_TYPE", value: this.doc_type },
        { column: "DOC_ID", value: this.item.id ? this.item.id : -1 },
        { column: "PARENT_ID", operator: "IS_NULL" },
        { column: "ACTIVE", value: "1" },
      ];
    } else this.WhereExt = [{ column: "PARENT_ID", operator: "IS_NULL" }];
    this.klist++;
    let r = await this.requette(EXTENSION);
    if (r) {
      this.ends = r.extensions;
      ends = r.extensions;
      this.docs_headers[6].list = ends.map((elm) => elm.app);
    }

    if (this.doc_type == -1) {
      r = await this.requette(DATA);
      if (r) {
        this.users = r.users;
        this.groups = r.allgroups;
      }
    }
    if (this.categories) this.items = list_to_tree(this.categories);
    this.kt++;
  },
  computed: {
    large() {
      return this.drawer ? 10 : 12;
    },
    Qselect() {
      return DOCS_PAGES;
    },
    Qselectall() {
      return DOCS;
    },
    Qpermission_delete() {
      return DELETE_DOC_PERMISSION;
    },
  },
  methods: {
    file_added(n) {
      this.$emit("file_added", n);
      this.klist++;
    },
    cat_change(item) {
      this.CatScope = item;
      this.klist++;
    },
    show_properties() {
      this.isPropClose = false;
      this.fpp++;
    },
    contextmenu(e, item) {
      this.document = item;
      this.selitem = item;
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    async requette(query, v) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.loading = false;
        });
      return r;
    },
    async delete_file(item) {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir " +
            (this.retablir
              ? "restaurer"
              : item.isActive
              ? "supprimmer"
              : "supprimmer definitivement") +
            " ce document?",
          { color: this.retablir ? "" : "orange darken-3" }
        )
      ) {
        let r;
        if (this.retablir)
          r = await this.maj(UPDATE_DOC, {
            doc: { id: item.id, active: 1, write_uid: this.$store.state.me.id },
          });
        else if (item.isActive)
          r = await this.maj(UPDATE_DOC, {
            doc: { id: item.id, active: 0, write_uid: this.$store.state.me.id },
          });
        else
          r = await this.maj(DELETE_DOC, {
            id: item.id,
          });
        if (r) {
          this.klist++;
          this.$emit("file_deleted", 1);
        }
      }
    },
    onActive() {
      this.active_save = this.active;
    },
    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) this.$store.dispatch("Changed", true);
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },

    async PreviewFile(file) {
      if (file.isurl == 0) {
        let xhr = new XMLHttpRequest();
        //const downloadUrl = `http://localhost/download.php?file=${file.url}`;
        //xhr.open("GET", downloadUrl, true);

        xhr.open("GET", api + file.url, true);
        xhr.responseType = "blob"; // Use arraybuffer instead of blob
        xhr.onprogress = function (pe) {
          this.doc_download = true;
          this.progress_value = parseInt(
            Math.round((pe.loaded / (file.c_size * 1000)) * 100)
          );
          this.pl++;
          if (this.progress_value >= 100) this.doc_download = false;
        }.bind(this);
        xhr.onload = function () {
          if (this.status === 200) {
            const JSZip = require("jszip");
            var zip = new JSZip();
            zip.loadAsync(this.response).then((contents) => {
              let promises = [];

              Object.keys(contents.files).forEach((filename) => {
                promises.push(
                  contents
                    .file(filename)
                    .async("nodebuffer")
                    .then((content) => {
                      return {
                        filename,
                        content,
                      };
                    })
                );
              });

              Promise.all(promises).then((files) => {
                files.forEach((file) => {
                  let ok = false;
                  let t;
                  if (ends) {
                    for (let index = 0; index < ends.length; index++) {
                      const element = ends[index];
                      if (file.filename.endsWith(element.ext)) {
                        ok = true;
                        t = element.app;
                        break;
                      }
                    }
                  }
                  if (ok) {
                    let blob = new Blob([file.content], {
                      type: t,
                    });

                    // Open the PDF in a new window
                    const pdfContentUrl = URL.createObjectURL(blob);
                    window.open(
                      pdfContentUrl,
                      "_blank",
                      "toolbar=yes,scrollbars=yes,resizable=yes"
                    );
                  }
                });
              });
            });
          }
        };
        xhr.send();
      } else window.open(file.filename, "_blank");
    },

    async downloadItem(file) {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Télecharger ce document?"
        )
      ) {
        let xhr = new XMLHttpRequest();
        //const downloadUrl = `http://localhost/download.php?file=${file.url}`;
        //xhr.open("GET", downloadUrl, true);
        xhr.open("GET", api + file.url, true);
        xhr.responseType = "blob";
        xhr.onprogress = function (pe) {
          this.doc_download = true;
          this.progress_value = parseInt(
            Math.round((pe.loaded / (file.c_size * 1000)) * 100)
          );
          if (this.progress_value >= 100) this.doc_download = false;
        }.bind(this);

        xhr.onload = function () {
          if (this.status === 200) {
            const JSZip = require("jszip");
            var zip = new JSZip();
            zip.loadAsync(this.response).then(function (contents) {
              Object.keys(contents.files).forEach(function (filename) {
                zip
                  .file(filename)
                  .async("nodebuffer")
                  .then(function (content) {
                    let blob = new Blob([content], {
                      type: "application/pdf",
                    });
                    let link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = file.filename;
                    link.click();
                  });
              });
            });
          }
        };
        xhr.send();
      }
    },
    close() {
      this.$emit("close");
    },
    FileSelect(item) {
      this.document = item;
      this.selitem = item;

      // if (this.document)
      //   this.docs_headers[16].tooltip = this.document.isActive
      //     ? "Modifier Document"
      //     : "Restaurer Document";
    },
    UpdateDocument(item) {
      this.isRevision = false;
      item.doc_type = this.doc_type;
      item.auth = this.auth;
      if (this.item) item.doc_id = this.item.id;
      this.document = item;
      this.selitem = item;
      if (this.document.isActive) {
        this.isDocClose = false;
        this.ff++;
      }
      if (this.document.isActive == false) {
        this.retablir = true;
        this.delete_file(item);
      }
    },
    OpenFileForm(item) {
      this.isRevision = false;
      item.doc_type = this.doc_type;
      item.auth = this.auth;
      if (this.item) item.doc_id = this.item.id;
      this.document = item;
      this.selitem = item;
      if (item.id < 0) {
        this.isDocClose = false;
        this.ff++;
      }
      // if (item.col == "nodoc") {

      // }
    },
    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },
    sumCol(key) {
      // sum data in give key (property)
      return this.list.reduce((a, b) => a + (b[key] || 0), 0);
    },
  },
};
</script>
